import { ReactComponent as payi } from 'assets/images/sidemenu/empty-wallet.svg';

const icons = {
  payi: payi
};


const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const payment = {
  id: 'location',
  title: 'payments',
  type: 'group',
  children: [
    {
      id: 'PaymentStatements',
      title: 'Payment Statements',
      type: 'collapse',
      icon: icons.payi,
      children: [
        {
          id: 'statements',
          title: 'Statements',
          type: 'item',
          url: '/admin/payment-statements/statements',
        },
        {
          id: 'invoices',
          title: 'Invoices',
          type: 'item',
          url: '/admin/payment-statements/invoices',
        },
        {
          id: 'withdrawal',
          title: 'LCDW Withdrawal Request',
          type: 'item',
          url: '/admin/payment-statements/lcdw-withdrawal',

        },
        {
          id: 'payments',
          title: 'LCDW Payment Statement',
          type: 'item',
          url: '/admin/payment-statements/lcdw-payment-statement',

        }
      ]
    },


  ]
};


const modulesToCheck = ['Statements', 'Invoices', 'LCDW_withdrawal', 'LCDW_payment_statement'];

if (logUserType !== 'super_admin') {

  if (payment && Array.isArray(payment.children) && loginpermission && Array.isArray(loginpermission)) {

    modulesToCheck.forEach(moduleId => {
      const module = payment.children.find(child => child.id === moduleId);

      if (module) {
        const modulePermission = loginpermission.find(permission => permission.module === moduleId);
        if (modulePermission) {
          if (modulePermission.create === 0 &&
            modulePermission.read === 0 &&
            modulePermission.update === 0 &&
            modulePermission.deletePermission === 0) {
            payment.children = payment.children.filter(child => child.id !== moduleId);
          }
        }
      }
    });
  }
  else {
    console.error('support.children or loginpermission is undefined or not an array');

  }
}

if (payment.children.length === 0) {
  payment.title = '';
}


export default payment;