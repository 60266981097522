import { ReactComponent as requesti } from 'assets/images/sidemenu/route-square.svg';

const icons = {
  requesti: requesti
};


const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const request = {
    id: 'request',
    title: 'Request',
    type: 'group',
    children: [
        {
            id: 'ViewRequests',
            title: 'View Request',
            type: 'collapse',
            icon:icons.requesti,
            children: [
             {
                    id: 'lcdw-services',
                    title: 'LCDW Services',
                    type: 'item',
                    url: '/admin/request/lcdw-services',
             },
              {
                id: 'utilities-plan',
                title: 'Utilities Plan',
                type: 'item',
                url: '/admin/request/utilitiesplan',
              },
              {
                id: 'authorities-approval',
                title: 'Authorities Approval',
                type: 'item',
                url: '/admin/request/authorities-approval',
             
              },
              {
                id: 'survey',
                title: 'Survey',
                type: 'item',
                url: '/admin/request/survey',
             
              },
              {
                id: 'gpr-surveys',
                title: 'GPR Surveys',
                type: 'item',
                url: '/admin/request/gpr-surveys',
             
              }
            ]
          },
  
   
    ]
  };


  const modulesToCheck = ['lcdw_services', 'Utilities_plan', 'Authorities_approval', 'Survey', 'GPR_surveys'];

  if (logUserType !== 'super_admin') {

    if (request && Array.isArray(request.children) && loginpermission && Array.isArray(loginpermission)) {

      modulesToCheck.forEach(moduleId => {
        const module = request.children.find(child => child.id === moduleId);

        if (module) {
          const modulePermission = loginpermission.find(permission => permission.module === moduleId);
          if (modulePermission) {
            if (modulePermission.create === 0 &&
                modulePermission.read === 0 &&
                modulePermission.update === 0 &&
                modulePermission.deletePermission === 0) {
                request.children = request.children.filter(child => child.id !== moduleId);
            }
          }
        }
      });

    } else {
      console.error('support.children or loginpermission is undefined or not an array');
    }
  }

  if (request.children.length === 0) {
    request.title = '';
  }
  
  export default request;