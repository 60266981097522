import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { APP_DEFAULT_PATH_BASE_URL } from '../config';

const chance = new Chance();
// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};
//alert('test');
const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};
const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSession2 = (logintype) => {
  if (logintype) {
    localStorage.setItem('logintype', logintype);
  } else {
    localStorage.removeItem('logintype');
  }
};
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext2 = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get('/api/account/me');
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.log(err);
        dispatch({
          type: LOGOUT
        });
      }
    };
    init();
  }, []);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn === 'true') {
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: null // You might want to fetch the user info here if needed
        }
      });
    }
  }, []);

  const login = async (email, password, serviceToken) => {
    console.log('get', email, password);
    if (password === '') {
      try {
        const response = await axios.post(`${APP_DEFAULT_PATH_BASE_URL}api/get-mobile`, { email });

        console.log('check login value', response);
        let { rolepermission, user, logintype } = response.data;

        rolepermission = rolepermission ? rolepermission : [];

        setSession(serviceToken);
        setSession2(logintype);

        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });

        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('rolepermission', JSON.stringify(rolepermission));
      } catch (error) {
        console.log(error);
        throw {
          error: error.error || 'An unexpected error occurred',
          status: error?.status,
          data: error?.data
        };
      }
    } else {
      // // const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await axios.post(`${APP_DEFAULT_PATH_BASE_URL}api/companysignin`, { email, password });
        console.log('response company', response);
        const { rolepermission, user, logintype } = response.data;

        setSession(serviceToken);
        setSession2(logintype);

        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });

        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('rolepermission', JSON.stringify(rolepermission));
      } catch (error) {
        console.log(error);
        throw {
          error: error.error || 'An unexpected error occurred',
          status: error?.status,
          data: error?.data
        };
      }
    }
    // Save login state to local storage
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;
    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }
    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = async (email) => {
    try {
      // console.log('email',email);
      await axios.post(`${APP_DEFAULT_PATH_BASE_URL}api/company-logout`, { email });
    } catch (error) {
      console.error('Error logging out:', error);
    }
    setSession(null);
    // localStorage.removeItem('logintype');
    setSession2(null);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
    localStorage.removeItem('rolepermission');

    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};
  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  return (
    <JWTContext2.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext2.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext2;
