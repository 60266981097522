
//import  CustomIcon from 'assets/images/logo-icon.svg'
import { ReactComponent as dashboard } from 'assets/images/sidemenu/dashboard.svg';
import { ReactComponent as user } from 'assets/images/sidemenu/user.svg';
import { ReactComponent as map } from 'assets/images/sidemenu/map.svg';
import { ReactComponent as requesti } from 'assets/images/sidemenu/route-square.svg';
import { ReactComponent as payi } from 'assets/images/sidemenu/empty-wallet.svg';
import { ReactComponent as seti } from 'assets/images/sidemenu/setting.svg';
import { ReactComponent as back } from 'assets/images/sidemenu/logout2.svg';

const icons = {
  dashboard: dashboard,
  user:user,
  requesti: requesti,
  payi: payi,
  seti: seti,
  map:map,
  back: back
};

const lcdw = {
  id: 'navigation',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/lcdw/dashboard',
      icon: icons.dashboard 
    },
    {
        id: 'user-profile',
        title: 'User Profile',
        type: 'item',
        url: '/lcdw/user-profile',
        icon: icons.user 
      },
      {
        id: 'request-list',
        title: 'Request List',
        type: 'collapse',
        icon: icons.requesti,
        children: [
          {
            id: 'lcdw',
            title: 'LCDW Services',
            type: 'item',
            url: '/lcdw/request/lcdw-services-request'
          },
          {
            id: 'new-request-list',
            title: 'New Request List',
            type: 'item',
            url: '/lcdw/request/lcdw-new-request-list'
          }
        ]
      },
      {
        id: 'billing-payment',
        title: 'Billing & Payment',
        icon: icons.payi,
        type: 'collapse',
        children: [
          {
            id: 'withdrawal',
            title: 'Withdrawal Payments',
            type: 'item',
            url: '/lcdw/billing-payments/withdrawallist',
          },
          {
            id: 'bank-list',
            title: 'Bank List',
            type: 'item',
            url: '/lcdw/billing-payments/banklist',
          },
        ]
      },
      {
        id:'change-password',
        title: 'Change Password',
        type: 'item',
        url: '/lcdw/change-password',
        icon:icons.seti
      },
      {
        id:'activity',
        title: 'Activity',
        type:'item',
        url: '/lcdw/activity',
        icon:icons.user 
      },
      {
        id:'login-logs',
        title: 'Login Logs',
        type: 'item',
        url: '/lcdw/login-logs',
        icon:icons.user
      },
      {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        icon: icons.back,
        url: '/logout'
      }
  ]
  
};

export default lcdw;
