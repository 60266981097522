import { Navigate } from 'react-router-dom';

const DocPermissionGuard = ({ children }) => {
  // Get `documentverify` from localStorage
  const userDetails = localStorage.getItem('user');

  let documentverify = null;

  if (userDetails) {
    const documentDedails = JSON.parse(userDetails); // Parse it only if it's not null
    console.log('documentDedails :', documentDedails.documentStatus);
  
    documentverify = documentDedails.documentStatus;
  } else {
    console.error('User details not found in localStorage.');
    // Handle the case where 'user' is not found in localStorage
  }
  // Convert to number if stored as a string
  const isDocumentVerified = Number(documentverify) === 1;
  console.log('isDocumentVerified :',isDocumentVerified);
  // Check access permissions
  if (!isDocumentVerified) {
    return <Navigate to="/maintenance/404_forbidden" replace />;
  }

  return children;
};

export default DocPermissionGuard;
