import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/CompanyMainLayout';
//import CommonLayout from 'layout/CommonLayout';
// import CommonLayout from 'layout/CommonLayout';

import Loadable from 'components/Loadable';
import CompanyAuthGuard from 'utils/route-guard/CompanyAuthGuard';
import PermissionGuard from 'utils/route-guard/companyPermissionGuard';
import DocpermissionGuard from 'utils/route-guard/CompanyDocpermissionGuard';
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));

const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));
const MaintenanceErrorFor = Loadable(lazy(() => import('pages/maintenance/error/404_forbidden')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/company/dashboard')));
const UserProfile = Loadable(lazy(() => import('pages/company/userprofile')));
// const  UserDocumentUpload= Loadable(lazy(() => import('pages/company/userdocumentuploaded')));
const UserDocumentList = Loadable(lazy(() => import('pages/company/DocumentManager/DocumentList')));
const AddLocation = Loadable(lazy(() => import('pages/location/LocationAdd')));
const SamplePage2 = Loadable(lazy(() => import('pages/extra-pages/sample-page2')));

//usermanagement
const ListOfSubUser = Loadable(lazy(() => import('pages/company/usermanagement/viewusers')));
const ViewSubUserActivity = Loadable(lazy(() => import('pages/company/usermanagement/viewcompanysubuseractivity')));

// Roles
const ListOfRoles = Loadable(lazy(() => import('pages/company/company_listrole')));
const AddRoles = Loadable(lazy(() => import('pages/company/company_addrole')));
const Permission = Loadable(lazy(() => import('pages/company/company_permission')));
const EditRoles = Loadable(lazy(() => import('pages/company/company_editrole')));

const Payments = Loadable(lazy(() => import('pages/company/payments')));

const Settings = Loadable(lazy(() => import('pages/company/settings')));
const UserActivity = Loadable(lazy(() => import('pages/company/useractivity')));
const LoginLogs = Loadable(lazy(() => import('pages/company/logs')));

const Payment = Loadable(lazy(() => import('pages/company/paymentreports')));
const Location = Loadable(lazy(() => import('pages/company/locationreports')));

const ViewLocation = Loadable(lazy(() => import('pages/company/LocationRequestManager/locationRequest')));

const ThankyouPage = Loadable(lazy(() => import('pages/company/LocationRequestManager/thankyoupage')));
//Other Locations
const LCDWServices = Loadable(lazy(() => import('pages/company/OtherLocation/lcdwservices')));
const UtilitiesPlan = Loadable(lazy(() => import('pages/company/OtherLocation/utilitiesplan')));
const AuthoritiesApproval = Loadable(lazy(() => import('pages/company/OtherLocation/authoritiesapproval')));
const Survey = Loadable(lazy(() => import('pages/company/OtherLocation/servey')));
const GPRSurveys = Loadable(lazy(() => import('pages/company/OtherLocation/GPRsurveys')));
//Request list

const UtilitiesRequestList = Loadable(lazy(() => import('pages/company/LCDWRequest/UtilitiesPlanRequest')));
const LCDWRequestList = Loadable(lazy(() => import('pages/company/LCDWRequest/lcdwservice')));
const AuthoritiesRequestList = Loadable(lazy(() => import('pages/company/LCDWRequest/AuthoritiesApproval')));
const SurveyList = Loadable(lazy(() => import('pages/company/LCDWRequest/SurveyRequest')));
const GPRSurveysList = Loadable(lazy(() => import('pages/company/LCDWRequest/GPRSurveys')));

const LcdwView = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/lcdwView')));
const UtilityView = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/utilityView')));
const AuthorityView = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/authoritiesView')));
const SurveyView = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/surveyView')));
const GPRSurvey = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/gprsurveysView')));

const LocatinOrderView = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/locationordersView')));
//assignedlocation
const ChangePassword = Loadable(lazy(() => import('pages/company/changepassword')));

const AssignedLocation = Loadable(lazy(() => import('pages/company/assignedlocation/payment-request-list')));

//photoapproval
const PhotoApproval = Loadable(lazy(() => import('pages/company/photoApproval/photoApprList')));
const EditLocationData = Loadable(lazy(() => import('pages/company/photoApproval/EditLocationData')));
const ViewLocationData = Loadable(lazy(() => import('pages/company/photoApproval/ViewLocationData')));

const PaymentForm = Loadable(lazy(() => import('pages/company/LocationRequestManager/paymentform')));
const TemplateCms = Loadable(lazy(() => import('pages/cms/template')));
const Logout = Loadable(lazy(() => import('pages/company/logout')));

const Invoices = Loadable(lazy(() => import('pages/company/assignedlocation/Paymentinvoice')));

const DemoPage = Loadable(lazy(() => import('pages/demopage')));
// ==============================|| MAIN ROUTES ||============================== //
import { WidgetStatistics, PaginationTable } from 'pages/extra-pages/ViewLayout';

const ViewLayout = () => {
  return (
    <div>
      <WidgetStatistics />
      <PaginationTable />
    </div>
  );
};

const MainRoutes = {
  id: 'navigation',
  title: 'Navigation',
  // Breadcrumbs: true,
  type: 'group',
  path: '/',
  children: [
    {
      path: 'logout',
      Breadcrumbs: false,
      element: <Logout />
    },
    {
      path: '/:title',
      Breadcrumbs: false,
      element: <TemplateCms />
    },
    {
      path: '/',
      Breadcrumbs: true,
      title: 'Home',
      element: (
        <CompanyAuthGuard>
          <MainLayout />
        </CompanyAuthGuard>
      ),
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          path: 'company/dashboard',
          element: <SamplePage />
        },
        {
          id: 'user-profile',
          title: 'User Profile',
          type: 'item',
          path: 'company/user-profile',
          element: (
            // <DocpermissionGuard>
            <PermissionGuard moduleId="userprofile">
              <UserProfile />
            </PermissionGuard>
            // </DocpermissionGuard>
          )
        },
        {
          id: 'documentlist',
          title: 'Document Manager',
          type: 'item',
          path: 'company/documentlist',
          element: (
            // <DocpermissionGuard>
            <PermissionGuard moduleId="documentmanager">
              <UserDocumentList />
            </PermissionGuard>
            // </DocpermissionGuard>
          )
        },
        {
          id: 'add',
          title: 'Locations Add',
          type: 'item',
          path: 'company/location/add',
          // element: < AddLocation />
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="locations">
                <AddLocation />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          id: 'view',
          title: 'Locations View',
          type: 'item',
          path: 'company/location/view',
          // element: <ViewLayout /> // Use AddTableLayout for adding data with a table
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="locations">
                <ViewLayout />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          path: 'company/location/photo-approval',
          // element: <SamplePage2 /> // Use AddTableLayout for adding data with a table
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="locations">
                <SamplePage2 />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          id: 'user-mangement',
          title: 'User Management',
          type: 'item',
          path: 'company/user-management',
          // element: <ListOfSubUser /
          children: [
            {
              id: 'view-user',
              title: 'View User',
              type: 'item',
              path: 'view-user',
              // element: < Payment />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="user_management">
                    <ListOfSubUser />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            }
          ]
        },
        {
          // id: 'user-mangement',
          // title: 'View User',
          // type: 'item',
          path: 'company/user-activity/:id',
          // element: <ViewSubUserActivity />
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="user_management">
                <ViewSubUserActivity />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          id: 'roles',
          title: 'Roles',
          path: 'company/roles',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'list-of-roles',
              title: 'List of Roles',
              type: 'item',
              path: 'list-of-roles',
              element: (
                <PermissionGuard moduleId="role_permission">
                  <ListOfRoles />
                </PermissionGuard>
              )
            },
            {
              id: 'edit-roles',
              title: 'Edit Roles',
              type: 'item',
              path: 'edit-roles/:id',
              // element: < EditRoles/>
              element: (
                <PermissionGuard moduleId="role_permission">
                  <EditRoles />
                </PermissionGuard>
              )
            },
            {
              id: 'add-roles',
              title: 'Add Roles',
              type: 'item',
              path: 'add-roles',
              // element: <AddRoles />
              element: (
                <PermissionGuard moduleId="role_permission">
                  <AddRoles />
                </PermissionGuard>
              )
            },
            {
              id: 'permission',
              title: 'Permission',
              type: 'item',
              path: 'permission',
              // element: <Permission />
              element: (
                <PermissionGuard moduleId="role_permission">
                  <Permission />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          id: 'payments',
          title: 'Payments',
          type: 'item',
          path: 'company/payments',
          // element: <Payments/>
          element: (
            <PermissionGuard moduleId="payments">
              <Payments />
            </PermissionGuard>
          )
        },
        {
          id: 'settings',
          title: 'Settings',
          type: 'item',
          path: 'company/settings',
          // element: < Settings />
          element: (
            <PermissionGuard moduleId="settings">
              <Settings />
            </PermissionGuard>
          )
        },
        {
          id: 'reports',
          title: 'Reports',
          path: 'company/reports',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'payment-reports',
              title: 'Payment Reports',
              type: 'item',
              path: 'payment-reports',
              // element: < Payment />
              element: (
                <PermissionGuard moduleId="reports">
                  <Payment />
                </PermissionGuard>
              )
            },
            {
              id: 'location-reports',
              title: 'Location Reports',
              type: 'item',
              path: 'location-reports',
              // element: < Location />
              element: (
                <PermissionGuard moduleId="reports">
                  <Location />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          id: 'location-view',
          title: 'View Location',
          type: 'item',
          path: 'company/location-view',
          // element: < ViewLocation />
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="view_location">
                <ViewLocation />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          id: 'user-activity',
          title: 'User Activity',
          type: 'item',
          path: 'company/user-activity',
          // element: < UserActivity />
          element: (
            <PermissionGuard moduleId="useractivity">
              <UserActivity />
            </PermissionGuard>
          )
        },
        {
          id: 'login-logs',
          title: 'Login Logs',
          type: 'item',
          path: 'company/login-logs',
          // element: < LoginLogs />
          element: (
            <PermissionGuard moduleId="loginlogs">
              <LoginLogs />
            </PermissionGuard>
          )
        },
        {
          id: 'view-invoices',
          title: 'View Invoices',
          type: 'item',
          path: 'company/view-invoices/:id',
          // element: <ViewLocation  />
          element: (
            // <PermissionGuard moduleId="Location">
            <Invoices />
            // </PermissionGuard>
          )
        },
        {
          id: 'other-request',
          title: 'Other Request',
          path: 'company/other-location',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'lcdw-service',
              title: 'LCDW Service',
              type: 'item',
              path: 'lcdw-service',
              // element: <LCDWServices />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="other_location">
                    <LCDWServices />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'utilities-plan',
              title: 'Utilities Plan',
              type: 'item',
              path: 'utilities-plan',
              // element: <UtilitiesPlan />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="other_location">
                    <UtilitiesPlan />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'authorities-approval',
              title: 'Authorities Approval',
              type: 'item',
              path: 'authorities-approval',
              // element: <AuthoritiesApproval />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="other_location">
                    <AuthoritiesApproval />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'survey',
              title: 'Survey',
              type: 'item',
              path: 'survey',
              // element: <Survey />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="other_location">
                    <Survey />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'gpr-surveys',
              title: 'GPR Surveys',
              type: 'item',
              path: 'gpr-surveys',
              // element: <GPRSurveys />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="other_location">
                    <GPRSurveys />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            }
          ]
        },
        {
          id: 'request-list',
          title: 'Request List',
          path: 'company/request',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'utilities-plan-request',
              title: 'Utilities Plan Request',
              type: 'item',
              path: 'utilities-plan-request',
              // element: <UtilitiesRequestList />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="request_list">
                    <UtilitiesRequestList />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'lcdw-services-request',
              title: 'LCDW Services',
              type: 'item',
              path: 'lcdw-services-request',
              // element: <LCDWRequestList />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="request_list">
                    <LCDWRequestList />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'authorities-approval-request',
              title: 'Authorities Approval',
              type: 'item',
              path: 'authorities-approval-request',
              // element: <AuthoritiesRequestList />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="request_list">
                    <AuthoritiesRequestList />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'survey-request',
              title: 'Survey',
              type: 'item',
              path: 'survey-request',
              // element: <SurveyList />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="request_list">
                    <SurveyList />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'gpr-surveys-request',
              title: 'GPR Surveys',
              type: 'item',
              path: 'gpr-surveys-request',
              // element: <GPRSurveysList />
              element: (
                <DocpermissionGuard>
                  <PermissionGuard moduleId="request_list">
                    <GPRSurveysList />
                  </PermissionGuard>
                </DocpermissionGuard>
              )
            },
            {
              id: 'lcdw-view-request',
              title: 'View Request',
              type: 'item',
              path: 'view-request/:id',
              element: <LcdwView />
            },
            {
              id: 'utility-request',
              title: 'Utility View',
              type: 'item',
              path: 'utility/view-request/:id',
              element: <UtilityView />
            },
            {
              id: 'authorities-request',
              title: 'Authorities View',
              type: 'item',
              path: 'authorities/view-request/:id',
              element: <AuthorityView />
            },
            {
              id: 'survey-request',
              title: 'Survey View',
              type: 'item',
              path: 'survey/view-request/:id',
              element: <SurveyView />
            },
            {
              id: 'GPR-survey-request',
              title: 'GPR Survey View',
              type: 'item',
              path: 'GPR-survey/view-request/:id',
              element: <GPRSurvey />
            }
          ]
        },
        {
          id: 'location-orders',
          title: 'Location Orders',
          type: 'item',
          path: 'company/location-orders',
          // element: <AssignedLocation />
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="location_orders">
                <AssignedLocation />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          id: 'location-orders-view',
          title: 'View',
          type: 'item',
          path: 'company/location-orders/:id',
          element: <LocatinOrderView />
        },
        {
          id: 'location-data',
          title: 'Location Data',
          type: 'item',
          path: 'company/location-data',
          // element: <PhotoApproval />
          element: (
            <DocpermissionGuard>
              <PermissionGuard moduleId="location_data">
                <PhotoApproval />
              </PermissionGuard>
            </DocpermissionGuard>
          )
        },
        {
          id: 'view-data',
          title: 'View Data',
          type: 'item',
          path: 'company/view-data/:id',
          element: <ViewLocationData />
        },
        {
          id: 'payment-form',
          title: 'Checkout Page',
          type: 'item',
          path: 'company/payment-form',
          element: (
            // <Elements stripe={stripePromise}>
            <PaymentForm />
            // </Elements>
          )
        },
        {
          id: 'demopage',
          title: 'Demopage',
          type: 'item',
          path: 'company/demopage',
          element: <DemoPage />
        },
        {
          path: 'company/thankyou-page',
          element: <ThankyouPage />
        },
        {
          id: 'edit-data',
          title: 'Edit Data',
          type: 'item',
          path: 'company/edit-data/:id',
          element: <EditLocationData />
        },
        {
          id: 'change-password',
          title: 'Change Password',
          type: 'item',
          path: 'company/change-password',
          element: <ChangePassword />
        }
      ]
    },
    {
      path: '/maintenance',
      Breadcrumbs: false,
      // element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        },
        {
          path: '404_forbidden',
          element: <MaintenanceErrorFor />
        }
      ]
    }
  ]
};

export default MainRoutes;
