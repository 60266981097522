
//import  CustomIcon from 'assets/images/logo-icon.svg'
import { ReactComponent as dashboard } from 'assets/images/sidemenu/dashboard.svg';
import { ReactComponent as user } from 'assets/images/sidemenu/user.svg';

const icons = {
  dashboard: dashboard,
  user: user,
};

const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const support = {
  id: 'navigation',
  title: 'Navigation',
  type: 'group',
  breadcrumbs: true,
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/admin/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'company',
      title: 'Company',
      type: 'collapse',
      icon: icons.user,
      breadcrumbs: true,
      children: [
        {
          id: 'view-company',
          title: 'View Company',
          type: 'item',
          url: '/admin/company/view',

        },
        {
          id: 'view-user',
          title: 'View Users',
          type: 'item',
          url: '/admin/company/users',

        }
      ]
    },
    {
      id: 'lcdw',
      title: 'LCDW',
      type: 'collapse',
      icon: icons.user,
      children: [
        {
          id: 'view-users',
          title: 'LCDW Users',
          type: 'item',
          url: '/admin/lcdw-user/view',

        },

        {
          id: 'add-user',
          title: 'View Users Activity',
          type: 'item',
          url: '/admin/lcdw-user/lcdw-users-activity',

        }
      ]
    },
    {
      id: 'staff_users',
      title: 'Staff',
      type: 'collapse',
      icon: icons.user,
      children: [
        {
          id: 'view-staff-users',
          title: 'Staff Users',
          type: 'item',
          url: '/admin/staff/staff-users',
        },
        {
          id: 'view-staff-users-activity',
          title: 'View User Activity',
          type: 'item',
          url: '/admin/staff/users-activity',
        },
        {
          id: 'follow_up_list',
          title: 'Follow Up List',
          type: 'item',
          url: '/admin/staff/follow-uplist',
        }
      ]
    },
    {
      id: 'admin_users',
      title: 'Admin',
      type: 'collapse',
      breadcrumbs: true,
      icon: icons.user,
      children: [
        {
          id: 'view-users',
          title: 'Admin Users',
          type: 'item',
          url: '/admin/admin-users/view-users',
        },
        {
          id: 'view-user-activity',
          title: 'View User Activity',
          type: 'item',
          url: '/admin/admin-users/view-user-activity',
        },

        {
          id: 'roles',
          title: 'Roles',
          type: 'collapse',
          breadcrumbs: true,
          icon: icons.seti,
          children: [
            {
              id: 'list-of-roles',
              title: 'List of Roles',
              type: 'item',
              url: '/admin/admin-users/roles/list-of-roles'
            },
          ]
        },
        {
          id: 'permission',
          title: 'Permission',
          type: 'item',
          url: '/admin/admin-users/permission',
        },
      ]
    },
  ]

};


const modulesToCheck = ['view_company', 'company_users', 'lcdw_users', 'lcdw_users_activty', 'staff_users', 'staff_users_activity', 'follow_up_list', 'admin_users', 'admin_users_activity', 'list_of_roles', 'Permission'];

if (logUserType !== 'super_admin') {
  if (support && Array.isArray(support.children) && loginpermission && Array.isArray(loginpermission)) {
    modulesToCheck.forEach(moduleId => {
      const module = support.children.find(child => child.id === moduleId);

      if (module) {
        const modulePermission = loginpermission.find(permission => permission.module === moduleId);

        if (modulePermission) {
          if (modulePermission.create === 0 &&
            modulePermission.read === 0 &&
            modulePermission.update === 0 &&
            modulePermission.deletePermission === 0) {
            support.children = support.children.filter(child => child.id !== moduleId);
          }
        }
      }
    });
  } else {
    console.error('support.children or loginpermission is undefined or not an array');
  }
}

if (support.children.length === 0) {
  support.title = '';
}

export default support;
