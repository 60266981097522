import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';
// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import Cookies from 'js-cookie';

import { APP_DEFAULT_PATH_BASE_URL } from '../config';

const chance = new Chance();
// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};
//alert('test');
const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};
const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSession2 = (logintype) => {
  if (logintype) {
    localStorage.setItem('logintype', logintype);
  } else {
    localStorage.removeItem('logintype');
  }
};
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get('/api/account/me');
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };
    init();
  }, []);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn === 'true') {
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: null // You might want to fetch the user info here if needed
        }
      });
    }
  }, []);

  const login = async (email, password, serviceToken) => {
    try {
      const response = await axios.post(`${APP_DEFAULT_PATH_BASE_URL}api/signin`, {
        email,
        password
      });
      const { token, rolepermission, logintype, user, userType } = response.data;

      Cookies.set('token', token, { path: '' }); 

      setSession(serviceToken);
      setSession2(logintype);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user
        }
      });
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('usertype', userType);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('rolepermission', JSON.stringify(rolepermission));
      return { success: true, message: 'Login successful' };
    } catch (error) {
      console.error('Login Error:', error);
      // Throwing error to be caught in onSubmit
      throw {
        error: error.error,
        status: error?.status,
        data:error?.data,
      };
    }
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;
    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }
    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = async (email) => {
    try {
      console.log('test logout')
      // Send a POST request to the backend logout endpoint with the email
      await axios.post(`${APP_DEFAULT_PATH_BASE_URL}api/logout`, { email });
    } catch (error) {
      console.error('Error logging out:', error);
    }
    // Remove cookies
    Cookies.remove('token', { path: '' }); 

    setSession(null);
    setSession2(null);
    dispatch({ type: LOGOUT });
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
    localStorage.removeItem('rolepermission');
  };

  const resetPassword = async () => {};
  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};
JWTProvider.propTypes = {
  children: PropTypes.node
};
export default JWTContext;
